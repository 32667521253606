import React from "react"
import styled from "styled-components"

import { rhythm } from "../utils/typography"

function Bio({ name, picture, bio, date }) {
  return (
    <Container>
      <img
        src={picture}
        alt={name}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 50,
          width: "50px",
          height: "50px",
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p style={{marginBottom: 0}}>
        <strong>{name}</strong>
        <br /> 
        {bio}
        <br />
        {date}
      </p>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

export default Bio
