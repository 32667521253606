import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
// import { DiscussionEmbed } from "disqus-react"

import Header from "../components/header"

import { HyperLink, Wrapper } from '../styles/blog'

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"


const NonStretchedImage = props => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        // margin: "0 auto", // Used to center the image
      },
    }
  }

  return <Img {...normalizedProps} />
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next, slug } = this.props.pageContext
    const author = this.props.data.allContributorYaml.edges[0].node

    // const disqusConfig = {
    //   shortname: "blogrocky",
    //   config: { identifier: post.id },
    // }

    if (typeof window !== 'undefined'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event' : 'gtm.dom',
        'pageType' : 'Post',
        'hostName' : 'blog.rocky.ag',
        'pagePath' : `${slug}`,
        'pageReferrer' : document.referrer,
      });
    }

    return (


      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          postImage={post.frontmatter.thumbnail.publicURL}
          slug={slug}
          rich={{
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": `https://blog.rocky.ag${slug}`
            },
            "headline": post.frontmatter.title,
            "image": post.frontmatter.thumbnail.publicURL,
            "datePublished": post.frontmatter.date,
            "dateModified": post.frontmatter.date,
            "author": {
              "@type": "Person",
              "name": author.name,
              "url":"https://blog.rocky.ag/autores"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Rocky Marketing Digital",
              "logo": {
                "@type": "ImageObject",
                "url": "https://rocky.ag/wp-content/themes/rocky/imagens/logo_white.png"
              }
            }
          }}
        />
        <Header location={this.props.location} borderRadius={true}/>
        <Wrapper>
          <h1>{post.frontmatter.title}</h1>
          <NonStretchedImage 
            fluid={post.frontmatter.thumbnail.childImageSharp.fluid} 
            presentationWidth={post.frontmatter.thumbnail.childImageSharp.presentationWidth} 
            style={{marginBottom: '25px', marginTop: '25px', borderRadius: '15px'}}
            alt={post.frontmatter.title + ' - Blog da Rocky 1' }
          />
          <Bio 
            name={author.name}
            bio={author.bio}
            date={post.frontmatter.date}
            picture={author.picture.publicURL}
          />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <MDXRenderer>{post.body}</MDXRenderer>
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <HyperLink to={`${previous.fields.slug}`} rel="prev">
                  ← {previous.frontmatter.title}
                </HyperLink>
              )}
            </li>
            <li>
              {next && (
                <HyperLink to={`${next.fields.slug}`} rel="next">
                  {next.frontmatter.title} →
                </HyperLink>
              )}
            </li>
          </ul>
          {/* <DiscussionEmbed {...disqusConfig} /> */}
        </Wrapper>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String! $authorId: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allContributorYaml(filter: {id: {eq: $authorId}}) {
      edges {
        node {
          name
          id
          bio
          picture {
            publicURL
          }
        }
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        contributor
        thumbnail {
          publicURL
          childImageSharp {
            fluid (maxWidth: 1700, maxHeight: 550) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
`
